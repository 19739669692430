import { createApp } from 'vue';
import { createStore } from 'vuex';
import Vue3TouchEvents from "vue3-touch-events";

import App from './App.vue';
import './registerServiceWorker'

const store = createStore({
	state(){
		return {
			counter: 0
		}
	},
	mutations: {
		increment(state){
			state.counter ++;
		}
	},
	getters: {
		finalCounter(state){
			return state.counter * 2;
		}
	}
});

const app = createApp(App);

app.use(store);
app.use(Vue3TouchEvents, {
	touchHoldTolerance: 600
});

app.mount('#app');
