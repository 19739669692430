<template>
<the-header></the-header>
  <base-container title="Vuex">
	<the-counter></the-counter>
	<button v-touch:hold="touchHoldHandler">Add 1</button>
  </base-container>
</template>

<script>
import TheHeader from './components/TheHeader.vue';
import BaseContainer from './components/BaseContainer.vue';
import TheCounter from './components/TheCounter.vue';

export default {
  components: {
    BaseContainer,
    TheCounter,
    TheHeader
  },
    methods: {
    touchHoldHandler(){
      this.$store.commit('increment');
    }
  }
};
</script>

<style>
@import url("assets/css/main.css");

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
}
</style>